<script>
	import { locateID } from "@xbs/lib-dom";
	import YearCalendar from "./YearCalendar.svelte";
	import YearPopup from "./YearPopup.svelte";

	export let model = {};
	export let calendars = [];
	$: ({ months, calendars, activeDate, getDayEvents } = model);
	let popupConfig;
	let node;

	function clickHandler(ev) {
		const id = locateID(ev);
		if (!id) {
			popupConfig = null;
		}
	}
	$: if (model && popupConfig) {
		showPopup(popupConfig);
	}
	function showPopupHandle(ev) {
		showPopup(ev.detail);
	}

	function showPopup(obj) {
		const { pos, date } = obj;
		const config = getPopupConfig(date);
		if (config) {
			return (popupConfig = { ...config, pos });
		}

		popupConfig = null;
	}

	function getPopupConfig(date) {
		const events = getDayEvents(date) || {};
		const { dayEvents, multievents } = events;
		if (multievents?.length || dayEvents?.length) {
			return { date, events };
		}
		popupConfig = null;
	}

</script>

<div
	on:click={clickHandler}
	bind:this={node}
	on:scroll={() => (popupConfig = null)}
	class="wx-event-calendar-year-view-wrapper"
	style="--wx-event-calendar-mark-color: {calendars[0].color.border}">
	{#each months as month (month.current)}
		<YearCalendar
			{month}
			bind:activeDate
			on:showPopup={showPopupHandle}
			on:action />
	{/each}
</div>
{#if popupConfig}
	<YearPopup config={popupConfig} {calendars} />
{/if}

<style>
	.wx-event-calendar-year-view-wrapper {
		flex: 1;
		display: flex;
		flex-wrap: wrap;
		gap: 48px;
		align-items: flex-start;
		justify-content: center;
		overflow-y: auto;
		padding: 60px 0;
	}
	:global(.wx-event-calendar-year-view-wrapper
			.wx-event-calendar-month-marker::after) {
		content: "";
		width: 4px;
		height: 4px;
		border-radius: 50%;
		position: absolute;
		bottom: 2px;
		left: 13px;
		/* background-color: var(--wx-event-calendar-mark-color);  */ /*Maybe first calendar color? */
		background-color: var(--wx-color-primary);
	}
	:global(.wx-event-calendar-year-view-wrapper
			.wx-event-calendar-month-marker) {
		font-weight: var(--wx-font-weight-md);
	}

</style>
