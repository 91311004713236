<script>
	import AgendaEventWrapper from "./AgendaEventWrapper.svelte";
	import { getContext } from "svelte";
	import { localeContext } from "@xbs/lib-scheduler";
	import { isToday } from "date-fns";
	import MinuteLine from "../MinuteLine.svelte";
	export let selected;
	export let day;
	export let calendars;
	export let calculateMinutesLinePosition;
	const templates = getContext("schedulerTemplates");
	const dateFormat =
		getContext(localeContext).getGroup("scheduler")("agendaDateFormat");
	$: date = day.date;
	$: events = day.events;
	$: today = events.length && isToday(date);

</script>

<div class="wx-event-calendar-agenda-day-wrapper">
	<div
		class="wx-event-calendar-agenda-date"
		data-day-toggle={true}
		data-cell={date?.valueOf()}>
		<svelte:component this={templates.agendaDate} {date} {dateFormat} />
	</div>
	<div class="wx-event-calendar-agenda-events-wrapper">
		{#each events as event}
			<AgendaEventWrapper {event} {calendars} {selected} />
		{/each}
		{#if today}
			<MinuteLine {calculateMinutesLinePosition} />
		{/if}
	</div>
</div>

<style>
	.wx-event-calendar-agenda-day-wrapper {
		display: flex;
		flex-shrink: 0;
		overflow-x: clip;
	}
	.wx-event-calendar-agenda-date {
		cursor: pointer;
		width: var(--wx-event-calendar_agenda-scale-width);
		padding: 6px 12px;
		border-bottom: var(--wx-border);
		flex-shrink: 0;
	}
	.wx-event-calendar-agenda-date:hover {
		background: var(--wx-background-hover);
	}
	.wx-event-calendar-agenda-events-wrapper {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		padding: 2px 0px 14px 0px;
		border: var(--wx-border);
		border-top: none;
		position: relative;
	}

</style>
