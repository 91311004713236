<script>
	export let event = {};
	export const calendar = {};

</script>

<span class="wx-event-calendar-label">{event.text}</span>

<style>
	.wx-event-calendar-label {
		flex-grow: 1;
		overflow: hidden;
		text-overflow: ellipsis;
		width: 100%;
	}

</style>
