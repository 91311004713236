<script>
	import { localeContext } from "@xbs/lib-scheduler";

	import { getContext, createEventDispatcher } from "svelte";
	import Checkbox from "./Checkbox.svelte";

	export let calendars = [];

	let isVisable = true;
	const dispatch = createEventDispatcher();
	const _ = getContext(localeContext).getGroup("scheduler");
	const config = getContext("schedulerConfig");
	$: ({ readonly } = $config);

	function toggle() {
		isVisable = !isVisable;
	}

	function add() {
		dispatch("sidebarAction", { action: "add" });
	}

</script>

<div class="wx-event-calendar-wrapper">
	<div
		class="wx-event-calendar-title wx-event-calendar-calendar_item"
		on:click={toggle}>
		<div class="wx-event-calendar-name">{_('Calendars')}</div>
		<div class="wx-event-calendar-buttons">
			{#if !readonly}
				<i class="wxi wxi-plus" on:click|stopPropagation={add} />
			{/if}
			<i class="wxi wxi-angle-{isVisable ? 'down' : 'up'}" />
		</div>
	</div>
	{#if isVisable}
		{#each calendars as calendar (calendar.id)}
			<div class="wx-event-calendar-calendar_item">
				<Checkbox {calendar} on:action on:sidebarAction />
			</div>
		{/each}
	{/if}
</div>

<style>
	.wx-event-calendar-wrapper {
		display: flex;
		flex-direction: column;
		font-size: var(--wx-font-size);
	}

	.wx-event-calendar-calendar_item {
		display: flex;
		align-items: center;
		cursor: pointer;
		user-select: none;
		border-top: var(--wx-border);
		width: 100%;
		height: 36px;
	}
	.wx-event-calendar-calendar_item:hover {
		background-color: var(--wx-background-alt);
	}

	.wx-event-calendar-title {
		justify-content: space-between;
		cursor: pointer;
		padding: 6px 12px;
	}
	.wx-event-calendar-name {
		font-weight: 600;
	}
	.wxi {
		color: var(--wx-color-font-disabled);
		font-size: var(--wx-line-height);
	}
	.wx-event-calendar-buttons {
		display: flex;
		align-items: center;
		gap: 8px;
	}

</style>
