<script>
	export let section = {};
	export const calendar = {};

</script>

<div class="wx-section-template-wrapper">
	{#if section.img}
		<img src={section.img} alt={section.label} class="wx-section-img" />
	{/if}
	<div class="wx-event-section-label">{section.label}</div>
</div>

<style>
	.wx-section-img {
		width: 30px;
		height: 30px;
		border-radius: 50%;
		margin-right: 8px;
	}

	.wx-section-template-wrapper {
		display: flex;
		align-items: center;
		padding: 9px 7px;
	}

	.wx-event-section-label {
		flex-grow: 1;
		overflow: hidden;
		display: flex;
		justify-content: start;
		text-overflow: ellipsis;
	}

</style>
