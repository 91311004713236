<script>
	import { getContext } from "svelte";
	import { format, localeContext } from "@xbs/lib-scheduler";

	export let date = {};
	export let dateFormat = "";
	const config = getContext("schedulerConfig");
	$: ({ tableHeaderHeight = 32 } = $config);

	const _dates = getContext(localeContext).getRaw().dateFnsLocale;

</script>

<div class="wx-event-calendar-date" style="height: {tableHeaderHeight}px;">
	{format(date, dateFormat, { locale: _dates })}
</div>

<style>
	.wx-event-calendar-date {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		color: var(--wx-color-font-alt);
	}

</style>
