<script>
	import { getContext } from "svelte";

	export let events;

	let collapsed = true;

	const templates = getContext("schedulerTemplates");

</script>

{#if $events.length}
	<div class="wx-event-calendar-collapsed-wrapper">
		<div
			class="wx-event-calendar-collapsed-parent-wrapper"
			on:click={() => (collapsed = !collapsed)}>
			<div
				class="wx-event-calendar-collapsed-parent wx-unassigned-event"
				data-id={$events[0].eventData.id}
				data-drag="source">
				<span>{$events[0].eventData.text}</span>
			</div>
			<i class="wxi wxi-angle-{collapsed ? 'right' : 'down'}" />
		</div>
		{#if !collapsed}
			<div class="wx-event-calendar-collapsed-child-wrapper">
				{#each $events as { eventData }, index (eventData.id)}
					<svelte:component
						this={templates.unassignedEvent}
						event={eventData} />
				{/each}
			</div>
		{/if}
	</div>
{/if}

<style>
	.wx-event-calendar-collapsed-wrapper {
		flex-grow: 1;
		overflow-y: auto;
		max-height: 100%;
	}
	.wx-event-calendar-collapsed-child-wrapper {
		flex-grow: 1;
		max-height: calc(100% - 35px);
		overflow-y: auto;
	}

	.wx-event-calendar-collapsed-parent-wrapper {
		display: flex;
		align-items: center;
		font-weight: var(--wx-font-weight-md);
	}
	.wx-event-calendar-collapsed-parent {
		flex-grow: 1;
	}
	.wxi {
		cursor: pointer;
		font-size: var(--wx-icon-size);
		color: var(--wx-icon-color);
	}
	.wx-unassigned-event {
		display: flex;
		justify-content: space-between;
		padding: 6px 10px;
		cursor: pointer;
		text-overflow: ellipsis;
	}

</style>
