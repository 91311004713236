<script lang="ts">
	export let name;
	export let size = 25;
	export let spin = false;
	export let click = null;
	export let active = false;
	export let clickable = !!click;

	const customIcons = {
		"arrow-right": {
			path: "M4,11V13H16L10.5,18.5L11.92,19.92L19.84,12L11.92,4.08L10.5,5.5L16,11H4Z",
		},
		"arrow-left": {
			path: "M20,11V13H8L13.5,18.5L12.08,19.92L4.16,12L12.08,4.08L13.5,5.5L8,11H20Z",
		},
		"arrow-up": {
			path: "M13,20H11V8L5.5,13.5L4.08,12.08L12,4.16L19.92,12.08L18.5,13.5L13,8V20Z",
		},
		"arrow-down": {
			path: "M11,4H13V16L18.5,10.5L19.92,11.92L12,19.84L4.08,11.92L5.5,10.5L11,16V4Z",
		},
	};

</script>

{#if customIcons[name]}
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		width={size}
		height={size}
		class="wx-event-calendar-uploader-icon"
		class:wx-event-calendar-uploader-clickable={clickable}
		class:wxi-spin={spin}
		fill="currentColor">
		<path d={customIcons[name].path} />
	</svg>
{:else}
	<i
		class="wx-event-calendar-uploader-icon wxi wxi-{name}"
		class:wxi-spin={spin}
		class:wxi-active={active}
		class:wx-event-calendar-uploader-clickable={clickable}
		style="font-size:{size}px;"
		on:click={click} />
{/if}

<style>
	.wx-event-calendar-uploader-icon {
		color: var(--wx-icon-color);

		display: flex;
		align-items: center;
	}
	.wx-event-calendar-uploader-clickable {
		cursor: pointer;
	}

	.wx-event-calendar-uploader-clickable:hover::before {
		color: var(--wx-color-primary) !important;
	}
	.wxi-active::before {
		color: var(--wx-color-primary);
	}

</style>
