<script>
	export let value = [];
	export let weekDays;

	function handleClick(id) {
		if (value.includes(id)) {
			if (value.length > 1) {
				value = value.filter(day => day !== id);
			}
		} else {
			value = [...value, id];
		}
	}

</script>

<div class="wx-event-calendar-week-control-wrapper">
	{#each weekDays as day (day.id)}
		<div
			class="wx-event-calendar-week-button"
			class:selected={value.includes(day.id)}
			on:click={() => handleClick(day.id)}>
			<span class="label">{day.name}</span>
		</div>
	{/each}
</div>

<style>
	.wx-event-calendar-week-control-wrapper {
		display: inline-flex;
		flex-wrap: nowrap;
		background: var(--wx-segmented-background);
		border: var(--wx-segmented-border);
		border-radius: var(--wx-segmented-border-radius);
		max-width: 100%;
	}

	.wx-event-calendar-week-button {
		display: flex;
		flex-wrap: nowrap;
		align-items: center;
		justify-content: center;
		text-decoration: none;
		letter-spacing: normal;
		font-family: var(--wx-button-font-family);
		font-size: var(--wx-button-font-size);
		line-height: var(--wx-button-line-height);
		font-weight: var(--wx-button-font-weigth);
		background: transparent;
		color: var(--wx-button-font-color);
		cursor: pointer;
		box-shadow: none;
		transition: none;
		max-width: 100%;
		width: 53px;
		height: 32px;
		user-select: none;
	}

	.wx-event-calendar-week-button,
	.wx-event-calendar-week-button:focus,
	.wx-event-calendar-week-button:active {
		outline: none;
	}

	.wx-event-calendar-week-button:hover {
		background: var(--wx-segmented-background-hover);
	}

	.wx-event-calendar-week-button.selected,
	.wx-event-calendar-week-button.selected:hover,
	.wx-event-calendar-week-button.selected:focus {
		background: var(--wx-color-primary);
		color: var(--wx-color-primary-font);
		cursor: default;
	}

</style>
