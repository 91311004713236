<script>
	import { getContext } from "svelte";
	import { format, getTimeFormat, localeContext } from "@xbs/lib-scheduler";

	export let timeScale = [];
	export let cellCss;
	const locale = getContext(localeContext);
	const _dates = locale.getRaw().dateFnsLocale;
	$: _ = locale.getGroup("scheduler");

	function formatTime(date) {
		return format(date, getTimeFormat(date, locale), {
			locale: _dates,
		});
	}

</script>

<div class="wx-event-calendar-time-column-wrapper">
	{#each timeScale as time (time.label)}
		<div class="wx-event-calendar-time-column-time {cellCss(time.label)}">
			{formatTime(time.label)}
		</div>
	{/each}
</div>

<style>
	.wx-event-calendar-time-column-wrapper {
		display: flex;
		height: 100%;
		flex-direction: column;
		box-sizing: border-box;
		width: var(--wx-event-calendar_hour-scale-width);
	}
	.wx-event-calendar-time-column-time {
		width: 100%;
		height: 50px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-right: var(--wx-border);
		border-bottom: var(--wx-border);
		flex-grow: 0;
		flex-shrink: 0;
		width: var(--wx-event-calendar_hour-scale-width);
		height: var(--wx-event-calendar_hour-cell-height);
	}

</style>
