<script>
	import { getContext } from "svelte";
	import { format, getTimeFormat, localeContext } from "@xbs/lib-scheduler";

	export let event = {};
	export const calendar = {};
	const locale = getContext(localeContext);
	const _dates = locale.getRaw().dateFnsLocale;

	let label = "";
	let eventTime = "";
	$: {
		const { start_date, text } = event;
		const start = format(
			start_date,
			getTimeFormat(event.start_date, locale),
			{ locale: _dates }
		);
		eventTime = start;
		label = text;
	}

</script>

<div class="wx-event-calendar-label">
	<span class="wx-event-calendar-marker" />
	<span class="wx-event-calendar-event-time">{eventTime}</span>
	<span> {label} </span>
</div>

<style>
	.wx-event-calendar-marker {
		display: inline-block;
		width: 10px;
		height: 10px;
		margin-right: 5px;
		border-radius: 50%;
		background-color: var(--wx-background);
	}
	.wx-event-calendar-event-time {
		font-weight: var(--wx-font-weight-md);
	}
	.wx-event-calendar-label {
		width: 100%;
		overflow: hidden;
		text-overflow: ellipsis;
	}

</style>
