<script>
	import { getContext } from "svelte";
	import { format, localeContext } from "@xbs/lib-scheduler";

	export let date = {};
	export let dateFormat = "";

	const _dates = getContext(localeContext).getRaw().dateFnsLocale;
	let day, month;
	$: {
		const formated = format(date, dateFormat, { locale: _dates }).split(
			" "
		);
		month = formated.pop();
		day = formated.join(" ");
	}

</script>

<div class="wx-event-calendar-date">
	<div class="wx-event-calendar-agenda-day">{day}</div>
	{month}
</div>

<style>
	.wx-event-calendar-date {
		display: flex;
		flex-direction: column;
		word-wrap: break-word;
		justify-content: center;
		width: 100%;
	}
	.wx-event-calendar-agenda-day {
		font-weight: var(--wx-font-weight-md);
	}

</style>
