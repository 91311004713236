<script lang="ts">
	import { isImage } from "@xbs/lib-scheduler";
	import Icon from "./Icon.svelte";

	export let data;

	const fileSize = ["b", "Kb", "Mb", "Gb", "Tb", "Pb", "Eb"];

	function removeAll() {
		data.set([]);
	}

	function remove(id) {
		data.update(v => v.filter(i => i.id !== id));
	}

	function formatSize(size) {
		let index = 0;
		while (size > 1024) {
			index++;
			size = size / 1024;
		}
		return Math.round(size * 100) / 100 + " " + fileSize[index];
	}
	function isPreview(obj) {
		const ext = obj?.url?.split(".").pop();
		const previewExt = obj?.previewURL?.split(".").pop();
		return isImage(previewExt) || isImage(ext);
	}

	function handlerCover(obj) {
		const { isCover, id } = obj;
		data.update(v => {
			return v.map(file => {
				if (file.id === id) {
					const copy = { ...obj };
					if (!isCover) {
						copy.isCover = true;
					} else {
						delete copy.isCover;
					}
					return copy;
				} else {
					delete file.isCover;
					return file;
				}
			});
		});
	}

</script>

{#if $data.length}
	<div class="wx-event-calendar-uploader-layout">
		<div class="wx-event-calendar-uploader-header">
			<i class="far fa-times" on:click={removeAll} />
		</div>
		<div class="wx-event-calendar-uploader-list">
			{#each $data as obj (obj.id)}
				<div class="wx-event-calendar-uploader-row">
					{#if isPreview(obj)}
						<a
							class="wx-event-calendar-uploader-upload-link"
							href={obj.url}
							download={obj.name}
							target="_blank"
							rel="noreferrer nofollow noopener">
							<img
								class="wx-event-calendar-uploader-thumb"
								src={obj.previewURL || obj.url}
								alt={obj.name} />
						</a>
					{:else}
						<div class="wx-event-calendar-uploader-file-icon">
							<Icon name="paperclip" size={20} />
						</div>
					{/if}
					<div class="wx-event-calendar-uploader-name">
						{obj.name}
					</div>
					{#if obj.file}
						<div class="wx-event-calendar-uploader-size">
							{formatSize(obj.file.size)}
						</div>
					{/if}
					<div class="wx-event-calendar-uploader-controls">
						{#if obj.status === 'client'}
							<Icon name="loading" spin />
						{:else if obj.status === 'error'}
							<Icon name="alert" />
							<Icon
								name="delete-outline"
								click={() => remove(obj.id)} />
						{:else if !obj.status || obj.status === 'server'}
							<div class="wx-event-calendar-uploader-hidden">
								<a
									class="wx-event-calendar-uploader-upload-link"
									href={obj.url}
									download={obj.name}
									target="_blank"
									rel="noreferrer nofollow noopener">
									<Icon name="external" clickable />
								</a>
								<Icon
									name="delete-outline"
									click={() => remove(obj.id)} />

								{#if isPreview(obj)}
									<Icon
										active={obj.isCover}
										name="pin-outline"
										click={() => handlerCover(obj)} />
								{/if}
							</div>
						{/if}
					</div>
				</div>
			{/each}
		</div>
	</div>
{/if}

<style>
	.wx-event-calendar-uploader-layout {
		display: flex;
		flex-direction: column;
		width: 100%;
	}
	i {
		cursor: pointer;
	}
	.wx-event-calendar-uploader-header {
		padding: 10px;
		border-bottom: 1px solid #ddd;
		text-align: right;
	}

	.wx-event-calendar-uploader-list {
		overflow: auto;
	}

	.wx-event-calendar-uploader-row {
		display: flex;
		align-items: center;
		gap: 10px;
		padding: 10px;
		border-bottom: 1px solid #ddd;
		flex-direction: column;
	}

	.wx-event-calendar-uploader-name {
		flex: 1;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.wx-event-calendar-uploader-controls {
		display: flex;
		height: 80px;
		width: 100%;
		justify-content: space-around;
	}
	.wx-event-calendar-uploader-hidden {
		display: none;
		width: 100%;
		justify-content: space-around;
	}
	.wx-event-calendar-uploader-row:hover .wx-event-calendar-uploader-hidden {
		display: flex;
	}
	.wx-event-calendar-uploader-file-icon {
		min-width: 40px;
		min-height: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.wx-event-calendar-uploader-thumb {
		width: 100%;
		max-width: 100%;
		max-height: 100%;
	}
	.wx-event-calendar-uploader-upload-link {
		text-decoration: none;
		display: flex;
	}

</style>
