<script>
	import { getEventStyles } from "@xbs/lib-scheduler";
	import { isPast } from "date-fns";
	import { getContext } from "svelte";

	export let event;
	export let calendars = [];

	const config = getContext("schedulerConfig");
	const tempaltes = getContext("schedulerTemplates");
	$: ({ readonly, dimPastEvents } = $config);
	$: eventData = event.eventData;
	$: calendar = calendars.find(c => c.id === eventData.type);
	$: drag = eventData.dragMove && !eventData.readonly ? "source" : null;

</script>

<div
	class="wx-event-calendar-event {readonly || eventData.readonly ? 'wx-event-calendar-readonly' : ''}"
	use:getEventStyles={{ event, backgroundFactor: 'border', calendars, dimPastEvents, isPast: isPast(eventData?.end_date) }}
	data-id={eventData.id}
	data-drag={drag}>
	<svelte:component
		this={tempaltes.monthEvent}
		event={eventData}
		{calendar} />
</div>

<style>
	.wx-event-calendar-event {
		position: absolute;
		gap: 10px;
		padding: 0 6px;
		color: var(--wx-color-font);
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		cursor: pointer;
	}

	.wx-event-calendar-readonly {
		cursor: default;
	}

</style>
