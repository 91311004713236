<script>
	import { getContext, createEventDispatcher } from "svelte";
	import { Modal, RadioButtonGroup } from "@xbs/svelte-wx";
	import { localeContext, updateRecurring } from "@xbs/lib-scheduler";

	export let event = {};
	export let initEvent;
	export let action = "update-event";
	const locale = getContext(localeContext);
	const _ = locale.getGroup("scheduler");
	const dispatch = createEventDispatcher();
	let mode = "only";
	let options = [
		{ value: "only", label: _("only") },
		{ value: "future", label: _("future") },
		{ value: "all", label: _("all") },
	];
	let title;
	$: {
		const splitedAction = action.split("-")[0];
		title = `${_(splitedAction === "update" ? "Edit" : "Delete")} ${_(
			"recurring event"
		)}`;
	}

	function cancel(reset = true) {
		dispatch("close-recurring-window", { reset });
	}

	function doAction() {
		updateRecurring(event, initEvent, action, mode, dispatch);
		cancel(false);
	}

</script>

<Modal {title} {cancel} ok={doAction}>
	<RadioButtonGroup {options} bind:value={mode} type="inline" />
</Modal>
