<script>
	import { Calendar, Dropdown } from "@xbs/svelte-wx";
	import { localeContext } from "@xbs/lib-scheduler";
	import { createEventDispatcher, getContext } from "svelte";

	const dispatch = createEventDispatcher();

	const locale = getContext(localeContext);
	const _dates = locale.getRaw().dateFnsLocale;
	export let title;
	export let date = new Date();
	export let datepicker;

	// calendar
	let markers;
	let current;
	let showCalendar = false;
	let node;

	// [VS] TODO: catch selectDate event from calendar and dispatch set-date event
	$: if (current) {
		dispatch("action", {
			action: "set-date",
			data: { value: current },
		});
	}

	function showDatepicker() {
		({ current, markers } = $datepicker);
		showCalendar = true;
	}

	function hideDatepicker(ev) {
		showCalendar = ev && node.contains(ev.target);
	}

</script>

<div class="wx-event-calendar-wrapper" bind:this={node}>
	<i class="wx-event-calendar-icon wxi-angle-left" data-id="prev" />
	<div
		class="wx-event-calendar-title"
		on:click={showDatepicker}
		bind:this={node}>
		{title($date, { locale: _dates })}
	</div>
	<i class="wx-event-calendar-icon wxi-angle-right" data-id="next" />
	{#if showCalendar}
		<div class="wx-event-calendar-calendar-wrapper">
			<Dropdown cancel={hideDatepicker} width="auto">
				<Calendar
					bind:current
					{markers}
					part="normal"
					buttons={false}
					cancel={hideDatepicker} />
			</Dropdown>
		</div>
	{/if}
</div>

<style>
	.wx-event-calendar-wrapper {
		position: relative;
		display: flex;
		align-items: center;
		gap: 5px;
		font-size: var(--wx-font-size-md);
		font-weight: var(--wx-font-weight-md);
		flex-grow: 30;
		justify-content: center;
	}

	.wx-event-calendar-title {
		text-transform: capitalize;
		cursor: pointer;
	}

	.wx-event-calendar-icon {
		cursor: pointer;
		color: var(--wx-icon-color);
		height: var(--wx-icon-size);
		width: var(--wx-icon-size);
		font-size: var(--wx-line-height-md);
	}
	.wx-event-calendar-icon::before {
		font-weight: var(--wx-font-weight-md) !important;
	}

	.wx-event-calendar-title:hover,
	.wx-event-calendar-icon:hover {
		opacity: 0.7;
	}

	.wx-event-calendar-calendar-wrapper {
		position: absolute;
		bottom: 0;
		left: 50%;
		width: 185px;
		transform: translateX(-50%);
		z-index: 10;
	}

</style>
