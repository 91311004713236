<script>
	import { getPopupPosition } from "@xbs/lib-dom";
	import {
		singleClickHandler,
		dblClickOnGrid,
		drag,
		localeContext,
		preSaveValidationError,
		getViewById,
	} from "@xbs/lib-scheduler";
	import {
		createEventDispatcher,
		setContext,
		tick,
		getContext,
	} from "svelte";

	import Navigation from "./Navigation/Navigation.svelte";
	import Month from "./MonthView/Month.svelte";
	import BaseColumnView from "./BaseLayout/BaseColumnView.svelte";
	import EventInfoDialog from "./EventInfoDialog.svelte";
	import CalendarsSidebar from "./CalendarSidebar/CalendarsSidebar.svelte";
	import YearView from "./YearView/YearView.svelte";
	import Agenda from "./AgendaView/Agenda.svelte";
	import RecurringModal from "./RecurringModal.svelte";
	import Timeline from "./Timeline/Timeline.svelte";
	import RightSidebar from "./RightSidebar/RightSidebar.svelte";

	export let store;
	const locale = getContext(localeContext)?.getGroup("scheduler");
	const { showModal } = getContext("wx-helpers");

	let unassignedToggle = false;

	const dispatch = createEventDispatcher();

	const {
		date,
		mode,
		viewModel,
		selectedId,
		selected,
		editorShape,
		calendars,
		config,
		popupInfo,
		edit,
		datepicker,
		sidebar,
		colors,
	} = store.getReactive();

	const view = {
		day: BaseColumnView,
		week: BaseColumnView,
		month: Month,
		year: YearView,
		agenda: Agenda,
		timeline: Timeline,
	};

	$: layout = getViewById($config, $mode)?.layout;

	$: model = $viewModel;
	$: {
		setContext("schedulerConfig", config);
		setContext("schedulerColors", colors);
	}

	$: ({
		readonly,
		dragCreate,
		dragMove,
		dragResize,
		eventInfoOnClick,
		dateClick,
	} = $config);

	$: skipDnD = readonly || $edit;

	let pos;
	let node;
	let layoutNode;
	let showRecurringModal = false;

	$: if ($popupInfo && node && selected) {
		getPos();
	}
	$: if ($mode) {
		unassignedToggle = false;
	}

	async function getPos() {
		await tick();
		pos = getPopupPosition(node, layoutNode, $selectedId);
	}

	function clickHandler(ev) {
		const eventNode = singleClickHandler(ev, dispatch, dateClick);
		node = eventNode || layoutNode;
	}

	function dblClickHandler(ev, disableCreate) {
		let cnf = $config;
		if (disableCreate) {
			cnf = {
				...cnf,
				createEventOnDblClick: false,
			};
		}
		dblClickOnGrid(ev, dispatch, model, cnf, locale);
	}

	let isValid;
	if (process.env.TRIALPACKAGE) {
		isValid = () => {
			if (typeof window === "undefined") return true;

			const l = location.hostname;
			const t = [
				// dhtmlx.com
				"ZGh0bWx4LmNvbQ==",
				// dhtmlxcode.com
				"ZGh0bWx4Y29kZS5jb20=",
				// webixcode.com
				"d2ViaXhjb2RlLmNvbQ==",
				// webix.io
				"d2ViaXguaW8=",
				// repl.co
				"cmVwbC5jbw==",
			];

			for (let i = 0; i < t.length; i++) {
				const x = window.atob(t[i]);
				if (x === l || l.endsWith("." + x)) return true;
			}

			return false;
		};
	}

	function recurringAction(ev) {
		const { action, data } = ev.detail;
		const { event, initEvent = event } = data;
		if (
			(action === "update-event" || action === "delete-event") &&
			!showRecurringModal
		) {
			return (showRecurringModal = { event, action, initEvent });
		}
	}
	function dragProxy(initEvent, event) {
		const err = preSaveValidationError(event);
		if (err) {
			showAlert(err);
			resetDrag();
			return;
		}
		showRecurringModal = { action: `update-event`, event, initEvent };
	}

	function resetDrag() {
		if (model.resetToInit) {
			model.resetToInit();
		}
	}

	function closeRecurringWindow(ev) {
		const { reset } = ev.detail;
		showRecurringModal = null;
		if (reset) {
			resetDrag();
		}
	}

	function alertHandle(ev) {
		const { message } = ev.detail;
		showAlert(message);
	}

	function showAlert(message) {
		showModal({
			message: locale(message),
			buttons: ["ok"],
		});
	}

	function toggleUnassigned() {
		unassignedToggle = !unassignedToggle;
	}

</script>

{#if process.env.TRIALPACKAGE && !isValid()}
	<mark
		class="wx-event-calendar_mark"
		class:wx-event-calendar_mark--error={process.env.TRIALDATE < new Date()}>
		{window.atob('VHJpYWw=')}
	</mark>
{/if}

<div class="wx-event-calendar-layout wx-event-calendar" bind:this={layoutNode}>
	<Navigation
		{date}
		{mode}
		{datepicker}
		toggleSidebar={$sidebar}
		title={$viewModel.title}
		on:action>
		<slot name="modeControl" slot="modeControl" let:mode {mode} />
	</Navigation>

	<div class="wx-event-calendar-content">
		{#if $sidebar}
			<CalendarsSidebar
				{datepicker}
				{date}
				sidebar={$sidebar}
				calendars={$calendars}
				on:action />
		{/if}
		<div
			class="wx-event-calendar-grid"
			on:click={clickHandler}
			on:dblclick={dblClickHandler}
			use:drag={{ triggers: ['data-resizer', 'data-id', 'data-cell'], skip: skipDnD, dispatch, model, trackMousesDiff: true, dragCreate, dragResize, dragMove, popupInfo: $popupInfo, calendars: $calendars, locale, dragProxy }}>
			<svelte:component
				this={view[layout]}
				on:toggleUnassigned={toggleUnassigned}
				unassignedToggle={unassignedToggle || $edit}
				{model}
				{selected}
				calendars={$calendars}
				on:action />
		</div>

		{#if $edit || unassignedToggle}
			<div
				on:click={clickHandler}
				on:dblclick={ev => dblClickHandler(ev, true)}
				use:drag={{ triggers: ['data-id'], skip: readonly, dispatch, model, dragMove, locale, dragProxy, dragCreate: false }}>
				<RightSidebar
					{unassignedToggle}
					editMode={$edit}
					editorShape={$editorShape}
					calendars={$calendars}
					{datepicker}
					{model}
					event={$selected || {}}
					on:toggleUnassigned={toggleUnassigned}
					on:alert={alertHandle}
					on:recurringAction={recurringAction}
					on:action />
			</div>
		{/if}
	</div>
</div>

{#if $popupInfo && $selected && $selectedId && eventInfoOnClick && !readonly && pos}
	<EventInfoDialog
		{pos}
		event={$selected}
		calendars={$calendars}
		on:recurringAction={recurringAction}
		on:action />
{/if}

{#if showRecurringModal}
	<RecurringModal
		{...showRecurringModal}
		on:action
		on:close-recurring-window={closeRecurringWindow} />
{/if}

<style>
	.wx-event-calendar {
		--wx-event-calendar_hour-scale-width: 70px;
		--wx-event-calendar_hour-cell-height: 42px;
		--wx-event-calendar_month-cell-min-height: 200px;
		--wx-event-calendar_agenda-scale-width: 110px;
	}

	.wx-event-calendar-layout {
		position: relative;
		display: flex;
		flex-direction: column;
		height: 100%;
		overflow: hidden;
		font-family: var(--wx-font-family);
		font-size: var(--wx-font-size);
		background-color: var(--wx-background);
		color: var(--wx-color-font);
	}

	.wx-event-calendar-content,
	.wx-event-calendar-grid {
		flex: 1;
		display: flex;
		overflow: hidden;
		position: relative;
	}

	.wx-event-calendar_mark {
		position: absolute;
		right: 34px;
		top: 12px;
		transform: rotate(30deg);
		color: #ccc;
		font-weight: 500;
		text-transform: uppercase;
		background: inherit;
		z-index: 10;
	}
	.wx-event-calendar_mark.wx-event-calendar_mark--error {
		color: red;
	}

</style>
