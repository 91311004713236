<script>
	import { afterUpdate, getContext } from "svelte";

	import TimeColumn from "./TimeColumn.svelte";
	import DataColumn from "./DataColumn.svelte";
	import MultiDayEvent from "../MonthView/MultiDayEvent.svelte";
	import { localeContext } from "@xbs/lib-scheduler";
	import { addDays } from "date-fns";
	export let model = {};
	export let selected;
	let scrollWidth;
	let table;
	const templates = getContext("schedulerTemplates");
	const dateFormat =
		getContext(localeContext).getGroup("scheduler")("dateFormat");
	$: ({
		timeScale,
		dateScale,
		dayEvents,
		multievents,
		getMultieventsHeight,
		calendars,
		config,
		calculateMinutesLinePosition,
	} = model);
	$: cellCss = config.cellCss;
	$: multieventsHeight = getMultieventsHeight($multievents) || "0px";
	$: week = {
		start_date: dateScale[0].value,
		end_date: addDays(dateScale[dateScale.length - 1].value, 1),
	};

	afterUpdate(() => {
		scrollWidth = table.offsetWidth - table.clientWidth + 1.5;
	});

</script>

<div class="wx-event-calendar-wrapper">
	<div class="wx-event-calendar-header" data-multievent={true}>
		<div class="wx-event-calendar-placeholder" />
		<div class="wx-event-calendar-header-data">
			{#each dateScale as date (date.value)}
				<div
					class="wx-event-calendar-header-date"
					data-day-toggle={true}
					data-cell={date.value?.valueOf()}
					on>
					<svelte:component
						this={templates.header}
						date={date.value}
						{dateFormat} />
				</div>
			{/each}
		</div>
		{#if scrollWidth}
			<div style="width:{scrollWidth}px" />
		{/if}
	</div>
	{#if $multievents.length}
		<div
			class="wx-event-calendar-multievents-wrapper"
			data-multievent={true}
			style="height: {multieventsHeight}">
			<div class="wx-event-calendar-placeholder-multiday" />
			<div class="wx-event-calendar-multievents-grid">
				{#each dateScale as date (date.value)}
					<div
						class="wx-event-calendar-multiday"
						data-cell={date.value?.valueOf()} />
				{/each}
				{#each $multievents as event (event.eventData.id)}
					<MultiDayEvent {event} {week} {selected} {calendars} />
				{/each}
			</div>
			{#if scrollWidth}
				<div style="width:{scrollWidth}px" />
			{/if}
		</div>
	{/if}
	<div
		class="wx-event-calendar-grid"
		bind:this={table}
		wx-table-auto-scroll="true">
		<TimeColumn {timeScale} {cellCss} />
		<div class="wx-event-calendar-data">
			{#each dateScale as date (date.value)}
				<DataColumn
					{calculateMinutesLinePosition}
					{cellCss}
					{timeScale}
					{selected}
					{date}
					events={$dayEvents}
					{calendars} />
			{/each}
		</div>
	</div>
</div>

<style>
	.wx-event-calendar-wrapper {
		flex: 1;
		display: flex;
		flex-direction: column;
	}
	.wx-event-calendar-grid {
		display: flex;
		width: 100%;
		flex: 1;
		min-height: 0;
		overflow-y: auto;
		position: relative;
	}
	.wx-event-calendar-header {
		display: flex;
		margin-left: -1px;
		border-bottom: var(--wx-border);
	}
	.wx-event-calendar-header-date {
		flex: 1 1 0;
		display: flex;
		align-items: center;
		justify-content: center;
		text-transform: capitalize;
		cursor: pointer;
	}
	.wx-event-calendar-header-data {
		display: flex;
		flex-grow: 1;
	}

	.wx-event-calendar-header-date:hover {
		background: var(--wx-background-hover);
	}
	.wx-event-calendar-placeholder,
	.wx-event-calendar-placeholder-multiday {
		height: 100%;
		flex-shrink: 0;
		width: var(--wx-event-calendar_hour-scale-width);
	}
	.wx-event-calendar-placeholder-multiday {
		display: flex;
		align-items: center;
		justify-content: center;
		border-right: var(--wx-border);
	}
	.wx-event-calendar-multievents-wrapper {
		border-bottom: var(--wx-border);
		display: flex;
		box-sizing: content-box;
	}
	.wx-event-calendar-multievents-grid {
		position: relative;
		flex-grow: 1;
		display: flex;
	}
	.wx-event-calendar-data {
		width: 100%;
		display: flex;
		height: fit-content;
	}
	.wx-event-calendar-multiday {
		display: flex;
		flex-grow: 1;
		height: 100%;
	}

</style>
