<script>
	export let option = {};

</script>

<div class="wx-event-calendar-combo-option">
	{option.label}
	{#if option.color}
		<div
			class="wx-event-calendar-color"
			style="background:{option.color.border}" />
	{/if}
</div>

<style>
	.wx-event-calendar-combo-option {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.wx-event-calendar-color {
		width: 10px;
		height: 10px;
	}

</style>
